import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue')
  // },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('../views/Register.vue')
  // },
  {
    path: "/forgot-pass",
    name: "ForgotPass",
    component: () => import("../views/ForgotPass.vue"),
  },
  {
    path: "/resetpass/:token",
    name: "resetpass",
    component: () => import("../views/resetpass.vue"),
  },
  {
    path: "/restaurant/:slug",
    name: "Restaurant",
    component: () => import("../views/Restaurant.vue"),
  },
  {
    path: "/basket/:slug",
    name: "Basket",
    component: () => import("../views/Basket.vue"),
  },
  {
    path: "/checkout/:slug",
    name: "Checkout",
    component: () => import("../views/checkout.vue"),
  },
  // {
  //   path: '/address',
  //   name: 'address',
  //   component: () => import('../views/address.vue')
  // },
  {
    path: "/peyment",
    name: "peyment",
    component: () => import("../views/peyment.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/history",
    name: "History",
    component: () => import("../views/History.vue"),
  },
  {
    path: "/paymentstatus",
    name: "Paymentstatus",
    component: () => import("../views/Paymentstatus.vue"),
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import("../views/imprint.vue"),
  },
  {
    path: "/externalOrderCheckout/:slug",
    name: "externalOrderCheckout",
    component: () => import("../views/externalOrderCheckout.vue"),
  },
  // {
  //   path: '/table-pay',
  //   name: 'TablePay',
  //   component: () => import('../components/table/TablePay.vue')
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// ???
router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
});

export default router;
