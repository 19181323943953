<template>
  <Dropdown
    v-if="deliveryTypes.length > 1"
    class="px-4 py-2 bg-top-menu-back-color md:rounded-2xl rounded-xl"
    width="64"
  >
    <template #trigger>
      <button
        type="button"
        href="#"
        class="flex items-center justify-center w-32 space-x-2 text-sm text-dynamic font-medium capitalize"
        :disabled="$store.getters.changingDeliveryType"
      >
        <DeliveryIcon :type="deliveryType" />
        <span class="flex items-center justify-between space-x-1">
          <span>
            {{ deliveryType == 'here' ? $t('Eat here') : $t(deliveryType) }}
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 h-4 mt-1" stroke="currentColor" fill="none" style="stroke-width:2px">
            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
        </span>
      </button>
    </template>
    <template #content>
      <DropdownLink
        href="#"
        class="flex items-center jutify-between space-x-2 text-dynamic font-medium capitalize md:py-3 py-4"
        v-for="(type, i) in deliveryTypes"
        :class="{hidden: type == deliveryType}"
        :key="i"
        @linkClick="changeDeliveryMethod(type)"
      >
        <DeliveryIcon :type="type" />
        <span>
          {{ type === 'here' ? $t('Eat here') : $t(type) }}
        </span>
      </DropdownLink>
    </template>
  </Dropdown>
</template>

<script>
import DeliveryIcon from "@/components/delivery/DeliveryIcon.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import DropdownLink from "@/components/dropdown/DropdownLink.vue";

export default {
  name: "DeliveryType",
  components: { DeliveryIcon, Dropdown, DropdownLink },
  computed: {
    deliveryTypes() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo.deliveryTypes;
    },
    deliveryType() {
      let type = this.$store.state.orderiomApiPackage.product.deliveryType;
      if (type == 3) {
        return "here";
      }
      if (type == 2) {
        return "pickup";
      }
      return "delivery";
    },
    restaurantId() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantId;
    },
  },
  methods: {
    async changeDeliveryMethod(deliveryType) {
      try {
        await this.$store.dispatch("product/changeDeliveryMethod", {
          deliveryType,
          restaurantId: this.restaurantId,
        });

        this.getCategoriesWithProducts();

        this.$emit("passInfoValidity", false);
        // this.$emit("close", this.deliveryType);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    async getCategoriesWithProducts() {
      const res = await this.$http.get("api/restaurant/category", {
        params: { restaurantId: this.restaurantId }
      })
      let categories = res.data.data;
      this.$store.commit("product/setSelectedCategory", categories[0]);
      const result = await this.$http.get("api/restaurant/category", {
        params: { restaurantId: this.restaurantId, extra: 1 },
      })
      if (result.data.data.length) categories = [...categories, ...result.data.data];
      await Promise.all(
        categories.map(async category => {
          const res = await this.$store.dispatch("product/getProducts", {
            category,
            restaurantId: this.restaurantId,
          });
          category.products = res.data;
        })
      )
      categories.push()
      this.$store.commit("product/setCategories", categories);
      this.$store.dispatch("hideBasketFromHeader" , true);
    },
  }
};
</script>
