<template>
  <header class="flex aic jcc bg-primary z-hard fixed w-full h-[80px]">
    <div class="flex area-min w-full px-2 justify-between items-center">
      <button
        type="button"
        class="flex aic jcc"
        @click="$emit('showMenu')"
        v-if="!customDesign"
      >
        <i class="fa fa-bars fs-24 text-dynamic"></i>
      </button>
      <div class="flex relative" v-if="customDesign">
        <svg
          class="w-6 h-6 fill-current text-dynamic"
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          viewBox="0 0 24 24"
        >
          <path
            d="M15.246 17c-.927 3.701-2.547 6-3.246 7-.699-1-2.32-3.298-3.246-7h6.492zm7.664 0c-1.558 3.391-4.65 5.933-8.386 6.733 1.315-2.068 2.242-4.362 2.777-6.733h5.609zm-21.82 0h5.609c.539 2.386 1.47 4.678 2.777 6.733-3.736-.8-6.828-3.342-8.386-6.733zm14.55-2h-7.28c-.29-1.985-.29-4.014 0-6h7.281c.288 1.986.288 4.015-.001 6zm-9.299 0h-5.962c-.248-.958-.379-1.964-.379-3s.131-2.041.379-3h5.962c-.263 1.988-.263 4.012 0 6zm17.28 0h-5.963c.265-1.988.265-4.012.001-6h5.962c.247.959.379 1.964.379 3s-.132 2.042-.379 3zm-8.375-8h-6.492c.925-3.702 2.546-6 3.246-7 1.194 1.708 2.444 3.799 3.246 7zm-8.548-.001h-5.609c1.559-3.39 4.651-5.932 8.387-6.733-1.237 1.94-2.214 4.237-2.778 6.733zm16.212 0h-5.609c-.557-2.462-1.513-4.75-2.778-6.733 3.736.801 6.829 3.343 8.387 6.733z"
          />
        </svg>
        <div @click="showLan = true" class="flex flex-col items-center">
          <span class="text-sm capitalize p-1 text-dynamic font-bold">{{
            locale
          }}</span>
        </div>
        <div
          v-if="showLan"
          class="absolute top-0 left-0 flex flex-col bg-primary-color px-3 rounded-md border-white border divide-y"
        >
          <span
            v-for="item in locales"
            :key="item"
            @click="changeLan(item)"
            class="font-bold text-sm capitalize p-1 text-dynamic"
            >{{ item }}</span
          >
        </div>
      </div>
      <router-link
        :to="logoLink"
        class="flex aic jcc w-40"
        :class="logo ? 'mt-5' : ''"
      >
        <img
          class="object-cover"
          :src="logo ? storage + logo : '/img/top-logo.png'"
        />
      </router-link>
      <router-link
        v-if="(slug || basketId) && hide"
        :to="{ name: 'Basket', params: {slug} }"
        class="cart button z-hard"
      >
        <div class="w-30p relative">
          <img src="/img/cart.png" alt="cart" class="w-full" />
          <span class="absolute -top-3 -right-2 text-center badge">{{
            counter
          }}</span>
        </div>
      </router-link>
    </div>
    <!-- Todo: move to products page -->
    <!-- <div class="flex flex-col flex-1 w-full">
      <DeliveryType />
    </div> -->
    <transition
      enter-active-class="animate__animated animate__slideInLeft"
      leave-active-class="animate__animated animate__slideOutLeft"
    >
      <menu-side v-if="showMenuSide" @close="$emit('closeMenu')" />
    </transition>
  </header>
</template>

<script>
import MenuSide from "./MenuSide.vue";
import DeliveryType from "@/components/delivery/DeliveryType.vue";
import { locales } from "@/plugins/i18n";

export default {
  props: ["showMenuSide"],
  data() {
    return {
      url: "",
      showLan: false,
    };
  },
  components: { MenuSide, DeliveryType },
  computed: {
    locales(){
      return locales;
    },
    logoLink() {
      const basket = this.$store.state.orderiomApiPackage.product.basketInfo;
      const tableName = basket?.table?.name;
      if (tableName) {
        return `/restaurant/${this.slug}?tableName=${tableName}`;
      }
      return `/restaurant/${this.slug}`;
    },
    locale() {
      return this.$i18n.locale;
    },
    slug() {
      return this.$store.state.mainRoutePath;
    },
    basketData() {
      return JSON.parse(localStorage.getItem("basket") || "[]");
    },
    basketId() {
      if (this.restaurantId)
        return JSON.parse(localStorage.getItem("basket")).find(
          (basket) => basket.restaurantId == this.restaurantId
        ).basketId;
      else if (this.$route.query.basketId) {
        return this.$route.query.basketId;
      }
      return null;
    },
    counter() {
      let quantity =
        this.$store.state.orderiomApiPackage.product.ShoppingCart.map((m) => {
          return m.quantity;
        });
      let count = quantity.reduce(function (a, b) {
        return a + b;
      }, 0);
      return count;
    },
    storage() {
      return process.env.VUE_APP_S3_STORAGE;
    },
    logo() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .logo;
    },
    customDesign() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .customDesign;
    },
    hide() {
      return this.$store.state.hideBasketFromHeader;
    },
  },
  methods: {
    changeLan(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("defaultLocale", lang);
      document.documentElement.lang = lang;
      this.showLan = false;
    },
  },
};
</script>

<style>
.animate__animated.animate__slideOutLeft {
  --animate-duration: 0.5s;
}
</style>

<style scoped>
.badge {
  width: 19px !important;
  height: 19px !important;
  border-radius: 4px !important;
  font-size: 11px;
  font-weight: bold;
  padding: 3px 3px 3px 4px !important;
  background: var(--dark-color);
  color: #ffffff !important;
}
</style>
