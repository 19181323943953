import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from '@/plugins/i18n';
import '@/plugins/registerServiceWorker';
import '@/plugins/prototypes';
import '@/plugins/directives';
import '@/plugins/components';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
