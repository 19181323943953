import Vue from "vue";
import VueSweetalert2 from 'vue-sweetalert2';
import VCalendar from 'v-calendar';
import ParagraphSkeleton from '@/components/skeletons/Paragraph';
import HeaderSkeleton from '@/components/skeletons/Header';
import ImageSkeleton from '@/components/skeletons/Image';

Vue.use(VueSweetalert2);
Vue.use(VCalendar, {
  componentPrefix: 'v',
});
Vue.component('ParagraphSkeleton', ParagraphSkeleton);
Vue.component('HeaderSkeleton', HeaderSkeleton);
Vue.component('ImageSkeleton', ImageSkeleton);
