<template>
	<div role="status" class="animate-pulse mb-4 w-48 h-2.5">
		<div class="h-full w-full bg-gray-400 rounded-full"></div>
		<span class="sr-only">Loading...</span>
	</div>
</template>

<script>
export default {

}
</script>
