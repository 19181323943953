<template>
  <a
    :href="href"
    class="block w-full px-4 py-2 text-sm leading-5 outline-none transition duration-150 ease-in-out"
    @click="$emit('linkClick')"
    >
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
  },
  emits: ['linkClick']
};
</script>
