import Vue from "vue";
import Vuex from "vuex";
import orderiomApiPackage from "orderiom-api-package";

Vue.use(Vuex);
const previousQRCodes = JSON.parse(localStorage.getItem('previous-QR-codes') || '[]');

const store = new Vuex.Store({
  state: {
    isAuthorized: false,
    table: null,
    themeColor: "#ffffff",
    mainRoutePath: "",
    slugNotFound: false,
    hideBasketFromHeader: true,
    saveQrcodeList: previousQRCodes,
    tableName: null
  },
  getters: {
    deliveryTypes(_, getters){
      // deliveryTypes here must be dependant to other restaurant configs such as 'closed'
      const result = getters.restaurantInfo.deliveryTypes || [];
      if(getters.restaurantInfo.closed){
        return result.filter(item => item !== 'here');
      }
      return result;
    },
    supportsTip(state){
      return !!state.orderiomApiPackage.restaurant.restaurantInfo.tip_request;
    },
    shoppingCart(state){
      return state.orderiomApiPackage.product.ShoppingCart;
    },
    currency(state){
      return state.orderiomApiPackage.restaurant.restaurantInfo.currency;
    },
    restaurantInfo(state){
      return state.orderiomApiPackage.restaurant.restaurantInfo;
    },
    basketInfo(state){
      return state.orderiomApiPackage.product.basketInfo || {};
    },
    deliveryTime(_, getters){
      return getters.basketInfo.deliveryTime || null;
    },
    loadingBasket(state){
      return !state.orderiomApiPackage.product.basketLoadedOnce || state.orderiomApiPackage.product.fetchingBasket;
    },
    deliveryType(_, getters){
      const id = getters.basketInfo.deliveryType || null;
      switch (id){
        case 1:
          return 'delivery';
        case 2:
          return 'pickup';
        case 3:
          return 'here';
        default:
          return null;
      }
    },
    paymentMethodNames(_, getters) {
      return getters.restaurantInfo.paymentMethodNames || {};
    }
  },
  mutations: {
    updateIsAuthorized(state, val){
      state.isAuthorized = val;
    },
    setTable(state, table) {
      state.table = table;
    },
    setThemeColor(state, color) {
      state.themeColor = color;
    },
    setMainRoutePath(state, route) {
      state.mainRoutePath = route;
    },
    setSlugNotFound(state, status) {
      state.slugNotFound = status;
    },
    setHideBasketFromHeader(state, status) {
      state.hideBasketFromHeader = status;
    },
    setSaveQrcodeList(state, { qrcode, pin }) {
      state.saveQrcodeList.push({ qrcode, pin });
      localStorage.setItem('previous-qr-codes', JSON.stringify(state.saveQrcodeList))
    },
    deleteItem(state, index) {
      state.saveQrcodeList.splice(index, 1);
      localStorage.setItem('previous-qr-codes', JSON.stringify(state.saveQrcodeList))
    },
    setTableName(state , name){
      state.tableName = name
    }
  },
  actions: {
    authorized({commit}){
      commit('updateIsAuthorized', true);
    },
    updateThemeColor({ commit }, color) {
      commit("setThemeColor", color);
    },
    updateMainRoutePath({ commit }, path) {
      commit("setMainRoutePath", path);
    },
    updateSlugNotFound({ commit }, status) {
      commit("setSlugNotFound", status);
    },

    hideBasketFromHeader({ commit }, status) {
      commit("setHideBasketFromHeader", status);
    },
    updateSaveQrcodeList({ commit }, qrcode) {
      commit("setSaveQrcodeList", qrcode);
    },
    deleteSaveQrcodeList({ commit }, itemIndex) {
      commit("deleteItem", itemIndex);
    },
  },
  modules: {},
});

Vue.use(orderiomApiPackage, { store })

export default store;
