<template>
  <div class="relative">
    <div @click="open = !open">
      <slot name="trigger" />
    </div>

    <div v-show="open" class="fixed inset-0 z-40" @click="open = false"></div>

    <Transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-show="open"
        class="absolute z-50 mt-2 rounded-md shadow-lg"
        :class="[widthClass, alignmentClasses]"
        style="display: none"
        @click="open = false"
      >
        <div class="ring-1 ring-primary ring-opacity-5" :class="contentClasses">
          <slot name="content" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    align: {
      type: String,
      default: 'right',
    },
    width: {
      type: String,
      default: '48',
    },
    contentClasses: {
      type: String,
      default: 'bg-primary rounded-2xl mt-1',
    },
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    widthClass() {
      return {
        48: 'w-48',
        64: 'w-64',
        md: 'w-screen max-w-xs'
      }[this.width.toString()];
    },
    alignmentClasses() {
      if (this.align === 'left') {
        return 'origin-top-left left-0';
      } else if (this.align === 'right') {
        return 'origin-top-right right-0';
      } else {
        return 'origin-top';
      }
    }
  },
  mounted() {
    console.log('mounted');
    document.addEventListener('keydown', this.closeOnEscape);
  },
  unmounted() {
    console.log('unmounted');
    document.removeEventListener('keydown', this.closeOnEscape);
  },
  methods: {
    closeOnEscape(e) {
      if (this.open && e.key === 'Escape') {
        this.open = false;
      }
    }
  }
};
</script>
