<template>
	<div>
		<div role="status" class="w-full animate-pulse">
			<div class=" bg-gray-200 h-72 rounded-4xl dark:bg-gray-700 mb-2.5"></div>
			<span class="sr-only">Loading...</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "ImageSkeleton",
}
</script>
