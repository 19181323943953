import Vue from "vue";
import VueI18n from 'vue-i18n'
import en from "@/i18n/en.json";
import de from "@/i18n/de.json";
import fr from "@/i18n/fr.json";
import orderiomApiPackage from "orderiom-api-package";

Vue.use(VueI18n);

const messages = {en, de, fr};
messages.en = Object.assign(messages.en, orderiomApiPackage.enMsg);
messages.de = Object.assign(messages.de, orderiomApiPackage.deMsg);
messages.fr = Object.assign(messages.fr, orderiomApiPackage.frMsg);

let browserLocale = navigator.language?.split('-')[0];
if(!Object.keys(messages).includes(browserLocale)){
  browserLocale = null;
}
if(browserLocale) window.isLocaleSetByBrowser = true;

const defaultLocale = browserLocale || localStorage.getItem("defaultLocale") || "de";
document.documentElement.lang = defaultLocale;



export const locales = Object.keys(messages);
export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'de',
  messages
});
