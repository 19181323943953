<template>
  <div id="app">
    <!-- <table-pay v-if="tableMode"/> -->
    <!-- <div v-if="showPayOptions" class="fixed top-0 left-0 flex flex-col space-y-8 items-center justify-center w-screen h-screen bg-primary z-hard">
        <img :src="logo ? storage+logo : '/img/top-logo.png'" class="absolute top-8 w-20" />
        <button @click="goForScan" class="flex flex-col items-center space-y-2 text-dynamic p-5 rounded-3xl shadow w-8/12 bg-black bg-opacity-10">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-24 h-24" viewBox="0 0 24 24">
            <path d="M24 13h-24v-3h24v3zm-14-5h1v-2h-2v1h1v1zm2-3h1v-1h-1v-1h-3v1h2v2h1v-1zm2 1h1v-5h-1v1h-1v-1h-4v1h3v1h2v3zm-1 1h1v-1h-1v1zm-10-1h3v-3h-3v3zm15 0h3v-3h-3v3zm-2 2v-7h7v7h-7zm1-1h5v-5h-5v5zm-2 1v-1h-1v1h1zm-3 0h1v-1h-1v1zm-11 0v-7h7v7h-7zm1-1h5v-5h-5v5zm11 13v1h1v-1h-1zm3-4h1v-1h-1v1zm2 3h2v1h-1v2h-1v1h-1v-1h-1v1h-1v-1h-1v-1h1v-2h-2v1h-3v1h1v1h-1v1h-1v-5h1v-1h2v-1h1v-1h1v1h2v1h-1v1h1v1h1v-1h1v1zm-1 1h-1v1h1v-1zm2-5v1h1v-1h-1zm2 0v1h1v-1h-1zm-3 2v-1h-1v1h1zm3 0v-1h-1v1h-1v1h1v1h1v-1h2v-2h-1v1h-1zm-9 6h1v-2h-1v2zm9-2v2h1v-1h1v-2h-1v1h-1zm-20-5h7v7h-7v-7zm1 6h5v-5h-5v5zm18 1v-1h-1v1h1zm-17-2h3v-3h-3v3zm19-1v-1h-1v1h1zm-13-5v2h1v-2h-1z"/>
          </svg>
          <span class="berlin-rounded-bold text-2xl tracking-tight">{{$t('Pay for the table')}}</span>
        </button>
        <button @click="goOnline" class="flex flex-col items-center space-y-2 text-dynamic shadow p-5 rounded-3xl w-8/12 bg-black bg-opacity-10">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-20 h-20" viewBox="0 0 24 24">
            <path d="M10.028 15h-5.413l-4.615-11h15l-.564 2h-11.428l2.938 7h2.678l1.404 2zm7.544-5.439l1.756-5.561h1.929l.743-2h-4.195l-2.489 7.979 2.256-.418zm-10.072 6.439c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm14.011-3.454c1.44 2.018 1.041 3.439 2.489 5.471l-5.585 3.983c-3.015-2.246-5.617-2.966-6.458-3.248-.801-.27-1.317-.783-1.14-1.428.181-.652.862-.846 1.424-.821.848.039 1.536.293 1.536.293l-3.896-5.461c-.348-.488-.234-1.165.254-1.512.486-.348 1.163-.234 1.511.253l2.639 3.693c.127.178.374.22.553.093.179-.127.22-.375.093-.553l-.65-.912 1.047-.261c.274-.067.562.04.726.27l.532.746c.127.179.374.22.553.093.179-.127.22-.375.093-.554l-.614-.861 1.027-.23c.27-.058.548.05.708.274l.452.634c.127.178.375.219.553.093.179-.127.22-.375.093-.553l-.507-.71.303-.054c1.052-.186 1.623.363 2.264 1.262zm-12.006-3.597c.676-.482 1.55-.498 2.201.002-.371-1.242-1.856-1.754-2.913-1-1.059.756-1.054 2.326-.003 3.079-.261-.778.039-1.599.715-2.081z"/>
          </svg>
          <span class="berlin-rounded-bold text-2xl tracking-tight">{{$t('order again')}}</span>
        </button>
    </div> -->
    <div v-if="showRestaurant">
      <Header
        @showMenu="showMenuSide = true"
        @closeMenu="showMenuSide = false"
        :showMenuSide="showMenuSide"
      />
      <div v-if="slugNotFound" class="pt-32 text-center">
        <span class="font-semibold">
          {{$t("We were unable to find your restaurant. Please enter the correct URL")}}
        </span>
      </div>
      <main v-else class="w-full relative">
        <router-view />
        <loading :isLoading="isLoading" />
      </main>
    </div>
    <div v-else class="px-5">
      <template v-for="i in 3">
        <ImageSkeleton :key="`image-${i}`" class="w-full mt-5" />
        <ParagraphSkeleton :key="`paragraph-${i}`" class="mx-auto my-5" />
      </template>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
// import TablePay from "@/components/table/TablePay.vue";
import Loading from "./components/Loading.vue";

export default {
  components: { Header, Loading, /*TablePay*/ },
  data() {
    return {
      // showPayOptions: true,
      showMenuSide: false,
      isLoading: false,
      showRestaurant: false,
      // tableMode: false,
      registration: null,
      updateExists: false,
    };
  },
  watch: {
    $route: function (newval) {
      if (newval) this.showMenuSide = false;
    },
    "$store.state.orderiomApiPackage.restaurant.restaurantInfo.customDesign"(
      newval
    ) {
      document.documentElement.style.setProperty(
        "--primary-color",
        newval?.primary_color || "#33dbd6"
      );
      document.documentElement.style.setProperty(
        "--text-dynamic",
        newval?.primary_text_color || "#ffffff"
      );
      this.$store.dispatch(
        "updateThemeColor",
        newval?.primary_color || "#33dbd6"
      );
      this.changeFavicon();
    },
    "$store.state.mainRoutePath": {
      immediate: true,
      async handler(slug) {
        if(!slug) return;

        const res = await this.$store.dispatch("restaurant/getRestaurantIdBySlug", slug);
        this.$store.dispatch('authorized');

        if (res?.type === "error") {
          this.$store.dispatch("updateSlugNotFound", true);
          const documentStyle = document.documentElement.style;
          documentStyle.setProperty("--primary-color", "#33dbd6");
          documentStyle.setProperty("--text-dynamic", "#ffffff");
          this.showRestaurant = true;
          this.changeFavicon();
          return;
        }

        // set global variable for orderiom-api-package
        window.dynamicData = {VUE_APP_RESTAURANT_ID: this.restaurantId};
        await this.$store.dispatch("restaurant/getRestaurantInfo", this.restaurantId)
        this.$store.dispatch("updateSlugNotFound", false);
        this.showRestaurant = true;

        !window.isLocaleSetByBrowser && this.changeLan()

        // NOTE: It is not recommended to set meta tags client side
        this.createMetaTags();

        const lastVisit = window.localStorage.getItem('visited');
        const params = Object.fromEntries(new URLSearchParams(window.location.search));
        const today = new Date().toISOString().slice(0, 10);
        this.$store.dispatch('restaurant/recordTheVisit', {
          qrCode: Boolean(params.tableId || params.tableName || params.qrcode),
          restaurantId: this.restaurantId,
          unique: !lastVisit || lastVisit !== today,
          new: !lastVisit
        }).then(res => {
          if (!res) return;
          window.localStorage.setItem('visited', today);
        })
      }
    }
  },
  computed: {
    restaurantId() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantId;
    },
    slugNotFound() {
      return this.$store.state.slugNotFound;
    },
    faveIcon() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .favicon;
    },
    storage() {
      return process.env.VUE_APP_S3_STORAGE;
    },
    logo(){
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo.logo
    },
    language(){
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo.lang
    }
  },
  methods: {
    // goOnline() {
    //   this.showRestaurant = true,
    //   this.showPayOptions = false
    //   this.showHeader= true
    // },
    // goForScan(){
    //   this.showRestaurant = false,
    //   this.showPayOptions = false
    //   this.showHeader = false,
    //   this.tableMode= true
    //   this.$router.push('/table-pay')
    // },
    enableInterceptor() {
      this.$http.interceptors.request.use(
        (config) => {
          this.isLoading = true;
          return config;
        },
        (error) => {
          this.isLoading = false;
          return Promise.reject(error);
        }
      );

      this.$http.interceptors.response.use(
        (response) => {
          this.isLoading = false;
          return response;
        },
        (error) => {
          this.isLoading = false;
          return Promise.reject(error);
        }
      );
    },
    changeFavicon() {
      if (this.faveIcon) {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
        }
        link.href = this.storage + this.faveIcon;
        console.log(link.href);
      } else {
        console.log("Favicon not set!");
      }
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
      this.$swal({
        title: this.$t("we have updates! please refresh."),
        showClass: {
          popup: "swal2-show md-warning",
        },
      }).then(res => {
        if (res.isConfirmed) {
          this.refreshApp()
        }
      })
    },
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload()
    },
    createMetaTags(){
      // NOTE: It is not recommended to set meta tags client side
      // NOTE: some values need to be changed like og:image
      const data = this.$store.getters.restaurantInfo;
      const description = `Willkommen bei ${data.name}, wo wir ein köstliches kulinarisches Erlebnis mit einer großen Auswahl an leckeren Gerichten bieten. Genießen Sie unseren erstklassigen Service, die gemütliche Atmosphäre und die außergewöhnliche Küche, die alle Geschmäcker anspricht. Besuchen Sie noch heute ${data.name} und kosten Sie die Aromen, die uns bei Feinschmeckern beliebt machen.`
      const title = `Ordermood | ${data.name}`;

      const tags = [
        // opengraph
        {type: 'property', field: 'og:type', content: 'website' },
        {type: 'property', field: 'og:site_name', content: data.name },
        {type: 'property', field: 'og:title', content: title },
        {type: 'property', field: 'og:image', content: data.logo ? this.$storage + data.logo : null },
        // {type: 'property', field: 'og:url', content: '' },
        {type: 'property', field: 'og:description', content: description },

        // Twitter
        {type: 'name', field: 'twitter:card', content: data.logo ? 'summary_large_image' : 'summary' },
        {type: 'name', field: 'twitter:title', content: title },
        {type: 'name', field: 'twitter:description', content: description },
        {type: 'name', field: 'twitter:image', content: data.logo ? this.$storage + data.logo : null },

        // Meta
        {type: 'name', field: 'description', content: description}
      ];

      document.title = title
      tags.forEach(tag => {
        if(!tag.content) return;
        const meta = document.createElement('meta');
        meta.setAttribute(tag.type, tag.field)
        meta.content = tag.content;
        document.head.appendChild(meta);
      })
    },
    changeLan() {
      this.$i18n.locale = this.language;
      localStorage.setItem("defaultLocale", this.language);
      document.documentElement.lang = this.language;
    },
  },
  async created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    this.enableInterceptor();
    !localStorage.getItem("publicToken") && await this.$store.dispatch("auth/auth");

    const urlParts = window.location.pathname.split("/");
    if(!urlParts.includes('paymentstatus')){
      const slug = urlParts[urlParts.length - 1].split('?')[0];
      this.$store.dispatch("updateMainRoutePath", slug);
    }else{
      this.showRestaurant = true;
    }
  },
};
</script>

<style>
@import 'sweetalert2/dist/sweetalert2.min.css';
@import 'animate.css/animate.min.css';
@tailwind base;
/*@tailwind components;*/
@tailwind utilities;

.animate__animated.animate__slideInRight,
.animate__animated.animate__slideInUp {
  --animate-duration: 0.7s;
}
.animate__animated.animate__slideOutDown {
  --animate-duration: 0.3s;
}
</style>
